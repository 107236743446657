import { default as _91path_93JF3sAE2yEoMeta } from "/Users/zougengyu/Projects/websocket-tester/app/pages/blog/[path].vue?macro=true";
import { default as indexZ1zfVNmtkqMeta } from "/Users/zougengyu/Projects/websocket-tester/app/pages/blog/index.vue?macro=true";
import { default as indexnMOAbWXrWxMeta } from "/Users/zougengyu/Projects/websocket-tester/app/pages/index.vue?macro=true";
import { default as _91path_936kRn6Q8ojuMeta } from "/Users/zougengyu/Projects/websocket-tester/app/pages/legal/[path].vue?macro=true";
import { default as indexPVlEkrvi03Meta } from "/Users/zougengyu/Projects/websocket-tester/app/pages/tester/index.vue?macro=true";
export default [
  {
    name: "blog-path",
    path: "/blog/:path()",
    meta: _91path_93JF3sAE2yEoMeta || {},
    component: () => import("/Users/zougengyu/Projects/websocket-tester/app/pages/blog/[path].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexZ1zfVNmtkqMeta || {},
    component: () => import("/Users/zougengyu/Projects/websocket-tester/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexnMOAbWXrWxMeta || {},
    component: () => import("/Users/zougengyu/Projects/websocket-tester/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-path",
    path: "/legal/:path()",
    meta: _91path_936kRn6Q8ojuMeta || {},
    component: () => import("/Users/zougengyu/Projects/websocket-tester/app/pages/legal/[path].vue").then(m => m.default || m)
  },
  {
    name: "tester",
    path: "/tester",
    component: () => import("/Users/zougengyu/Projects/websocket-tester/app/pages/tester/index.vue").then(m => m.default || m)
  }
]