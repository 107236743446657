export default defineNuxtPlugin(nuxtApp => {
  const api = $fetch.create({
    timeout: 10000,
    retry: 0,
    async onResponseError(response) {
      console.error('[fetch response error]', response)
      if (response.response.status === 401) {
        await nuxtApp.runWithContext(() => navigateTo('/auth/login'))
      }
    },
  })

  return {
    provide: {
      api,
    },
  }
})
