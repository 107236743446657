<script setup lang="ts">
import { ConfigProvider } from 'radix-vue'

const useIdFunction = () => useId()

const i18nHead = useLocaleHead({
  addSeoAttributes: true,
})

useHead({
  // i18n
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [...(i18nHead.value.meta || [])],
})
</script>

<template>
  <ConfigProvider :use-id="useIdFunction">
    <GradientBackgroundWrapper>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
      <Toaster />
      <NuxtLoadingIndicator color="var(--colors-primary)" />
    </GradientBackgroundWrapper>
  </ConfigProvider>
</template>

<style></style>
